.top-message-section {
  height: 40.3px;
  width: 100%;
  background: #48BB78;
  /* background: linear-gradient(90deg, #1D2C3D 0%, #2B2953 25.83%, #302955 47.19%, #322A54 64.37%, #332A4C 81.56%, #2B2332 100%); */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: fixed;
  color: #FFFFFF;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.top-message-section span {
  font-weight: 700;
}

.question {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  color: #E9E9E9;
  letter-spacing: 0.04em;
  line-height: 17px;
}

.just-askexim {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 17px;
  color: #9CF1DE;
}

.landing-container {
  height: 860px;
  /* background-image: url('../../assets/icons/HeroBackground.webp');
  background-size: 100% 100%; */
  background: #030518;
  width: 100%;
  padding-top: 12px;
}

.landing-navbar {
  display: flex;
  padding: 20px 102px;
  justify-content: space-between;
  align-items: center;
}

.middle-navbar-section {
  display: flex;
  gap: 55px;
  align-items: center;
  margin-left: -16px;
}

.links {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  color: #F5F5F5;
  cursor: pointer;
  position: relative;
}

.links svg {
  stroke: #F5F5F5;
}

.links:hover {
  font-weight: 600;
  text-shadow: 0px 4px 12px rgba(255, 255, 255, 0.25);
  color: #F5D872;
}
.links:hover svg {
  stroke: #F5D872;
}

.header-menu {
  position: absolute;
  vertical-align: top;
  z-index: 999;
  width: 166.53px;
  height: 105.32px;
  background: #03031B;
  border: 0.5px solid #FFFFFF;
  box-shadow: 2px 7px 20px rgba(104, 103, 240, 0.1);
  border-radius: 9px;
  top: 72px;
  margin-left: -40px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.header-menu-item {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  color: #EEEEFF;
  text-align: center;
  cursor: pointer;
}

.header-menu-item:hover {
  color: #F5D872;
}

.header-menu-separator {
  background: #EDEDFF;
  width: 100%;
  height: 0.8px;
}

.join-now {
  margin-left: 30%;
  margin-top: -16px;
}

.road-to-scripx {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 136.69%;
  text-align: center;
  background: linear-gradient(270.56deg, #E37C98 6.09%, #B95AFE 100.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  cursor: pointer;
}

.road-to-scripx:hover {
  text-shadow: 0px 4px 12px rgba(255, 255, 255, 0.25);
  font-weight: 600;
  color: #F5D872 !important;
}

.end-navbar-section {
  display: flex;
  gap: 42px;
  align-items: center;
}

.login-btn {
  width: 128px;
  height: 40px; 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  background: transparent;
  font-size: 18px;
  line-height: 136.69%;
  color: #fff;
  border-radius: 40px;
  border: 1px solid rgba(247, 169, 214, 1);
}

.login-btn:hover {
  background: #6867F0;
  border: none;
}

.login-btn:active {
  background: #8F8FFD;
  border: none;
}

.for-exporters {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  text-shadow: 0px 4px 12px rgba(245, 216, 114, 0.48);
  color: #FEFDE0;
  text-align: center;
  margin-top: 84px;
  letter-spacing: 0.085em;
}

.super-charge {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.super-charge h1 {
  color: #FFF;
  font-family: 'Gordita Regular';
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  max-width: 1118px;
}

.description {
  max-width: 733px;
  margin: 28px auto 0 auto;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #B8B8D5;
  font-family: 'Montserrat';
}

.action-group {
  margin-top: 52px;
  display: flex;
  gap: 40px;
  justify-content: center;
}

.get-started {
  width: 222px;
  height: 48px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  text-align: center;
  color: #FFFFFF;
  border: none;
  background: linear-gradient(90deg, #6867F0 16.22%, #6640D7 84.01%);
  border-radius: 40px;
}

.get-started:hover {
  background: #222186;
}

.get-started:active {
  background: #8F8FFD;
}

.watch-video {
  border: 0.8px solid #EAEAFD;
  border-radius: 40px;
  width: 222px;
  height: 48px;
  background: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  color: #EAEAFD;
}

.watch-video:hover {
  background: #6867F0;
  border: none;
}

.watch-video:active {
  background: #8F8FFD;
}

.play-icon {
  margin-bottom: 4px;
}

.doc-img {
  display: block;
  margin: -130px auto 68px;
}

.video-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-body {
  padding: 0;
  position: relative;
}
.modal-header {
  border-bottom: none;
}
.video-player {
  width: 600px;
  height: 350px;
}

.fade.modal.show .modal-dialog {
  max-width: max-content !important;
  top: 10%;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .super-charge {
    height: 124px;
    margin-top: 16px;
  }

  .for-exporters {
    margin-top: 72px;
  }

  .action-group {
    margin-top: 32px;
  }

  .description {
    font-size: 22px;
  }

  .join-now svg {
    height: 112px;
  }
}

@media screen and (max-width: 520px) {
  .top-message-section {
    display: none;
  }
  .landing-container {
    height: 454px;
    padding: 26px 32px;
  }
  .landing-mob-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .landing-navbar, .end-navbar-section {
    display: none;
  }
  .fade.modal.show .modal-dialog {
    margin: 0 auto;
    top: 25%;
  }
  .road-to-scripx {
    margin-left: auto;
    font-size: 12px;
    font-weight: 600;
  }
  .doc-img {
    width: calc(100vw - 60px);
    height: 181px;
    margin: -40px 30px 0 30px;
  }

  .for-exporters {
    font-size: 10px;
    margin-top: 48px;
  }
  .super-charge {
    margin-top: 16px;
  }

  .video-player {
    width: 350px;
    height: 200px;
  }

  .description {
    font-size: 14px;
    margin-top: -32px;
  }

  .super-charge svg {
    width: 300px;
    height: 136px;
    margin-top: -32px;
  }

  .action-group {
    margin-top: 42px;
    gap: 16px;
  }

  .get-started {
    width: 146px !important;
    height: 36px !important;
    font-size: 14px;
  }

  .play-icon {
    width: 18px;
    height: 18px;
    margin-left: 0;
    margin-top: 2px;
  }

  .watch-video {
    width: 146px;
    height: 36px;
    font-size: 14px;
    white-space: nowrap;
  }
  .join-now {
    margin-left: 3%;
  }
  .join-now svg {
    width: 188px;
    height: 48px;
  }

  .mobile-menu {
    width: calc(100% - 64px);
    height: auto;
    background: #03031B;
    border: 0.5px solid #FFFFFF;
    box-shadow: 2px 7px 20px rgba(104, 103, 240, 0.1);
    border-radius: 10px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 999;
    position: absolute;
    margin-top: 8px;
    left: 32px;
  }

  .header-menu-item {
    font-family: 'Montserrat';
    text-align: left;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #B8B8D5;
  }

  .links {
    font-size: 14px;
  }

  .links svg {
    width: 16px;
    height: 16px;
  }

  .platforms-menu {
    background: linear-gradient(89.84deg, rgba(152, 252, 218, 0.15) 1.17%, rgba(199, 143, 255, 0.15) 32.35%, rgba(238, 164, 204, 0.15) 63.74%, rgba(255, 193, 169, 0.15) 99.96%);
    border-radius: 6px;
    width: 100%;
    height: auto;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .platforms {
    font-family: 'Montserrat';
    background: #68527B;
    border-radius: 26px;
    font-weight: 500;
    font-size: 8px;
    color: #F6F6FF;
    text-align: center;
    width: 79px;
    padding-top: 2px;
    height: 16px;
  }

  .menu-item {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background: linear-gradient(88.84deg, #F7A9D6 0%, #858EF8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}