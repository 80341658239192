.successToastArea {
  position: fixed;
  /* bottom: 0 !important;
  justify-content: center;
  left: 40%; */
  right: 16px;
  top: 16px;
  margin: 16px;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  z-index: 10001;
  background: #48BB78;
  padding: 8px 32px;
  display: flex;
  gap: 8px;
}

.errorToastArea {
  position: fixed;
  right: 16px;
  top: 16px;
  margin: 16px;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  z-index: 10001;
  background: red;
  padding: 8px 32px;
  display: flex;
  gap: 8px;
}

.infoToastArea {
  position: fixed;
  right: 24px;
  top: 96px;
  margin: 16px;
  height: 40px;
  color: #fff;
  border-radius: 10px;
  z-index: 10001;
  background: #3171DE;
  padding: 8px 32px;
  display: flex;
  gap: 8px;
}