.pains-container {
  margin-bottom: 32px;
  margin-top: 138px;
}
.no-conflict {
  display: flex;
  justify-content: center;
}
.yellow-curve {
  margin-left: 50%;
  margin-top: 32px;
}
.pain-description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 160%;
  text-align: center;
  color: #81819C;
  margin-top: 8px;
}

.bottom-btn {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 108px;
}

.get-started-button {
  background: linear-gradient(90deg, #6867F0 16.22%, #6640D7 84.01%);
  border-radius: 40px;
  width: 222px;
  height: 48px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  text-align: center;
  color: #FFFFFF;
} 

.pain-list {
  margin: 102px 60px;
}

.pain-item-1 {
  background: #EFF8FF;
  border-radius: 10px;
  padding: 80px 47px;
  display: flex;
  gap: 106px;
  align-items: center;
}

.pain-item-2 {
  background: #FFF9F0;
  border-radius: 10px;
  padding: 80px 48px;
  display: flex;
  gap: 106px;
  align-items: center;
  margin-top: 100px;
}

.pain-item-3 {
  background: #F0F0FF;
  border-radius: 10px;
  padding: 80px 48px;
  display: flex;
  gap: 106px;
  align-items: center;
  margin-top: 100px;
}

.pain-item-4 {
  background: #FFF2F0;
  border-radius: 10px;
  padding: 80px 48px;
  display: flex;
  gap: 106px;
  align-items: center;
  margin-top: 100px;
}

.pain-content-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pain-title-1 {
  font-family: 'Gordita Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 142.18%;
  color: #5D21D2;
}

.pain-title-2 {
  font-family: 'Gordita Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 142.18%;
  color: #3B66E9;
}

.pain-title-3 {
  font-family: 'Gordita Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 142.18%;
  color: #6867F0;
}

.pain-title-4 {
  font-family: 'Gordita Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 142.18%;
  color: #3B66E9;
}

.pain-subtitle {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  color: #323254;
}

.pain-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 180%;
  color: #81819C;
  max-width: 541px;
}

.get-started-button:hover {
  background: #fff;
  color: #6867F0;
  box-shadow: 0px 4px 35px rgba(104, 103, 240, 0.51);
}
.get-started-button:active {
  background: #fff;
}


@media screen and (min-width: 900px) and (max-width: 1400px) {
  .pain-section {
    gap: 130px;
  }
  .pain-section-1 {
    margin-top: 120px;
  }
  .curve-img-1 {
    max-width: 580px !important;
  }
  .pain-img-2 {
    max-width: 500px;
    height: 680px;
    margin-top: -320px;
    margin-left: 44px;
  }
  .pain-section-2 {
    margin-top: 0;
  }
}

@media screen and (max-width: 520px) {
  .pains-container {
    margin-top: 48px;
  }
  .no-conflict svg {
    width: 212px;
    height: 36px;
  }
  .yellow-curve {    
    height: 8px;
    margin-left: 40%;
    width: 172px;
    height: 10px;
    margin-top: -124px;
  }

  .pain-description {
    font-size: 14px;
    max-width: 300px;
    margin: 0 auto;
  }

  .pain-list {
    margin: 40px 16px;
  }

  .pain-item-2, .pain-item-1, .pain-item-3, .pain-item-4 {
    padding: 34px 20px;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
  }

  .pain-title-1, .pain-title-2, .pain-title-3, .pain-title-4 {
    font-size: 12px;
  }

  .pain-subtitle {
    font-size: 14px;
  }

  .pain-info {
    font-size: 12px;
  }

  .pain-img {
    width: 212px;
    height: 114px;
  }

  .bottom-btn {
    margin-top: 32px;
    margin-bottom: 40px;
  }

  .get-started-button {
    width: 160px;
    height: 36px;
    font-size: 14px;
  }
}