.why-eximfiles-container {
  margin: 82px auto 92px auto;
  background: #fff;
}

.gradient-line {
  width: 54px;
  height: 4px;
  background-image: linear-gradient(rgba(234, 82, 248, 1), rgba(0, 102, 255, 1));
  margin: 0 auto;
}

.why-eximfiles-title {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 141%;
  letter-spacing: 0.01em;
  color: #03031b;
  text-align: center;
  margin-top: 20px;
}

.why-description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 170%;
  color: #6867F0;
  text-align: center;
  max-width: 650px;
  margin: 16px auto;
}

.why-list {
  display: flex;
  gap: 140px;
  margin-top: 72px;
  align-items: center;
  justify-content: center;
}
.why-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.why-title {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 136.69%;
  letter-spacing: 0.01em;
  color: #323254;
  margin-top: 40px;
}

.why-desc {
  margin-top: 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #81819C;
  max-width: 262px;
}

.curve-circle1 {
  width: 452px;
  height: 130px;
  margin-left: -125px;
  margin-bottom: -90px;
}

.end-curve {
  display: flex;
  justify-content: flex-end;
}

.curve-circle2 {
  width: 262px;
  height: 130px;
  margin-bottom: -90px;
}

@media screen and (max-width: 520px) {
  .gradient-line {
    width: 12px;
    height: 1px;
  }
  .why-eximfiles-container {
    margin: 44px auto;
  }
  .why-list {
    flex-direction: column;
    gap: 50px;
    margin: 44px auto;
  }
  .why-eximfiles-title {
    font-size: 18px;
    white-space: nowrap;
    margin-top: 10px;
  }
  .why-description {
    font-weight: 500;
    font-size: 12px;
    max-width: 250px;
  }
  .why-img {
    width: 78px;
    height: 78px;
  }
  .why-title {
    font-size: 16px;
  }
  .why-desc {
    font-size: 14px;
    max-width: 244px;
  }
  .curve-circle1 {
    width: 96px;
    height: 32px;
    margin: 0;
  }
  .curve-circle2 {
    width: 60px;
    height: 32px;
    margin: 0;
  }
}