body {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto !important;
}

@font-face {
  font-family: "Eudoxus Sans Regular";   /*Can be any text*/
  src: local("Eudoxus Sans Regular"),
    url("./assets/fonts/EudoxusSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus Sans Medium";   /*Can be any text*/
  src: local("Eudoxus Sans Medium"),
    url("./assets/fonts/EudoxusSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus Sans Light";   /*Can be any text*/
  src: local("Eudoxus Sans Light"),
    url("./assets/fonts/EudoxusSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Eudoxus Sans Bold";   /*Can be any text*/
  src: local("Eudoxus Sans Bold"),
    url("./assets/fonts/EudoxusSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Black";   /*Can be any text*/
  src: local("SF UI Display Black"),
    url("./assets/fonts/SF_UI_DisplayBlack.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Bold";   /*Can be any text*/
  src: local("SF UI Display Bold"),
    url("./assets/fonts/SF_UI_DisplayBold.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Heavy";   /*Can be any text*/
  src: local("SF UI Display Heavy"),
    url("./assets/fonts/SF_UI_DisplayHeavy.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Light";   /*Can be any text*/
  src: local("SF UI Display Light"),
    url("./assets/fonts/SF_UI_DisplayLight.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Medium";   /*Can be any text*/
  src: local("SF UI Display Medium"),
    url("./assets/fonts/SF_UI_DisplayMedium.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display SemiBold";   /*Can be any text*/
  src: local("SF UI Display SemiBold"),
    url("./assets/fonts/SF_UI_DisplaySemiBold.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display Thin";   /*Can be any text*/
  src: local("SF UI Display Thin"),
    url("./assets/fonts/SF_UI_DisplayThin.otf") format("truetype");
}

@font-face {
  font-family: "SF UI Display UltraLight";   /*Can be any text*/
  src: local("SF UI Display UltraLight"),
    url("./assets/fonts/SF_UI_DisplayUltraLight.otf") format("truetype");
}

@font-face {
  font-family: "Gordita Medium";
  src: url("./assets/fonts/GorditaMedium.otf") format("truetype");
}

@font-face {
  font-family: "Gordita Bold";
  src: url("./assets/fonts/GorditaBold.otf") format("truetype");
}

@font-face {
  font-family: "Gordita Regular";
  src: url("./assets/fonts/GorditaRegular.otf") format("truetype");
}