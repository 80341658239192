.other-features-container {
  background-image: url('../../assets/icons/KeyFeature.png');
  background-size: 100% 100%;
  width: 100%;
  height: 780px;
  padding-top: 98px;
}

.other-gradient-line {
  width: 54px;
  height: 4px;
  background: #F5D872;
  margin: 0 auto;
}
.other-features-title {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 141%;
  letter-spacing: 0.01em;
  background: linear-gradient(89.82deg, #98FCDA 1.17%, #C78FFF 32.34%, #EEA4CC 63.71%, #FFC1A9 99.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 24px;
  text-align: center;
}

.feature-list {
  display: flex;
  gap: 80px;
  justify-content: center;
  margin: 72px 0 102px 0;
}

.feature-card {
  width: 370px;
  height: 424px;
  background: #FFFFFF;
  box-shadow: 2px 7px 20px rgba(104, 103, 240, 0.3);
  border-radius: 20px;
  padding: 30px 26px;
  text-align: center; 
}

.feature-title {
  margin-top: 32px;
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 142.18%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #03031b;
}
.feature-desc {
  margin: 16px auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #81819C;
  max-width: 292px;
}

.feature-img {
  width: 160px;
  height: 150px;
  margin: auto;
}

.feature-img-3 {
  width: 192px;
  height: 150px;
  margin: auto;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .feature-list {
    gap: 60px;
  }
}

@media screen and (max-width: 520px) {
  .other-features-container {
    height: 1260px;
    padding-top: 40px;
  }
  .feature-list {
    flex-direction: column;
    margin-top: 50px;
    gap: 16px;
  }
  .other-gradient-line {
    width: 12px !important;
    height: 1px;
  }
  .other-features-title {
    font-size: 18px;
    margin-top: 10px;
  }
  .other-features-title img {
    width: 135px;
    height: 25px;
  }
  .feature-card {
    margin: 0 auto;
    width: 300px;
    height: 340px;
    padding-top: 38px;
    margin-bottom: 16px;
  }
  .feature-title {
    font-size: 16px;
    margin-top: 20px;
  }
  .feature-desc {
    font-size: 14px;
    max-width: 272px;
    margin: 8px auto 24px auto;
  }
  .feature-img {
    width: 117px;
    height: 110px;
  }
  .feature-img-3 {
    width: 117px;
    height: 110px;
  }
}