.hello-container {
  margin-top: 128px;
  text-align: center;   
}

.curve-section {
  display: flex;
  gap: 144px;
  justify-content: center;
}

.hello-title {
  font-family: 'Sansita Swashed';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 136.69%;
  text-align: center;
  color: #323254;
  margin-bottom: -68px;
}

.hello-title span {
  color: #3825C6;
}

.line {
  margin: 126px auto 0 auto;
  width: 54px;
  height: 4px;
  background: linear-gradient(#3825C6 95%, #5148DC 70%, #A367F0 94%);
}

.key-challenges {
  margin-top: 20px;
  font-family: 'Gordita Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 140%;
  text-align: center;
  color: #323254;
}

.no-more {
  margin-top: 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 106.18%;
  text-align: center;
  color: #81819C;
}

.challenge-img {
  margin-top: 63px;
  margin-bottom: 0;
}

.toggle-container {
  width: 509.05px;
  height: 60px;
  border: 0.75px solid #48BB78;
  border-radius: 57px;
  margin: 58px auto 94px auto;
  padding: 4px;
  box-shadow: 0px 0px 32px -8px rgba(104, 103, 240, 0.36);
}

.left-section-1 {
  display: flex;
  padding: 3px;
  width: 245px;
  height: 52px;
  background: #48BB78;
  border: 1px solid #48BB78;
  border-radius: 29px;
  text-align: center;
}

.left-curve-section {
  width: 388px;
  height: 122px;
}

.right-curve-section {
  width: 388px;
  height: 122px;
}

.smile-img {
  width: 44px;
  height: 44px;
}

.right-section-1 {
  display: flex;
  padding: 3px;
}

.with-eximfiles-1 {
  margin-left: 14px;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  color: #fff;
  white-space: nowrap;
}

.without-eximfiles-1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  color: #B8B8D5;
  margin: 10px 9px 0 9px;
  cursor: pointer;
  white-space: nowrap;
}

.left-section-2 {
  display: flex;
  padding: 3px;
}

.right-section-2 {
  display: flex;
  padding: 3px;
  width: 263px;
  height: 52px;
  background: #FF5454;
  border-radius: 29px;
  text-align: center;
  margin-left: 24px;
}

.with-eximfiles-2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  color: #B8B8D5;
  margin: 10px 9px 0 9px;
  cursor: pointer;
  white-space: nowrap;
}

.without-eximfiles-2 {
  margin: 10px 14px 0 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  color: #fff;
  white-space: nowrap;
}

@media screen and (max-width: 520px) {
  .hello-title {
    font-size: 16px;
  }
  .line {
    width: 12px;
    margin-top: 40px;
  }
  .key-challenges {
    font-size: 18px;
    margin-top: 10px;
  }
  .key-challenges svg {
    width: 307px;
    height: 22px;
  }
  .no-more {
    font-size: 12px;
    max-width: 220px;
    line-height: 19.2px;
    margin: 8px  auto;
  }
  .hello-container {
    margin-top: 48px;
  }
  .challenge-img {
    width: 269px;
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .toggle-container {
    width: 286px;
    height: 36px;
    margin-top: 16px;
    padding: 4px;
    margin-bottom: 50px;
  }
  .left-section-1 {
    width: 131px;
    height: 27px;
  }
  .with-eximfiles-1 {
    font-size: 12px;
    margin-top: 2px;
    margin-left: 8px;
  }
  .without-eximfiles-1 {
    font-size: 12px;
  }
  .left-curve-section {
    width: 106px;
    height: 30px;
  }
  .right-curve-section {
    width: 106px;
    height: 30px;
  }
  .curve-section {
    margin-top: 48px;
    gap: 63px;
  }
  .smile-img {
    width: 18px;
    height: 18px;
  }
  .without-eximfiles-1 {
    margin: 2px 6px 0 6px;
  }
  .with-eximfiles-2 {
    font-size: 12px;
    margin: 2px 0 0 8px;
  }
  .right-section-2 {
    width: 152px;
    height: 26px;
    margin-left: 4px;
  }
  .without-eximfiles-2 {
    font-size: 12px;
    margin: 2px 8px 0 10px;
  }
}