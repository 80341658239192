.blue-bar {
  margin: 0 42px 32px 42px;
  height: 486px;
  background-image: url('../../assets/icons/blueBar.png');
  background-size: 100% 100%;
  width: 100%;
  border-radius: 25px;
}

.blue-bar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 132px;
}

.map-section {
  margin-top: 50px;
}

.revolutionize {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 154%;
  background: linear-gradient(89.82deg, #98FCDA 1.17%, #C78FFF 32.34%, #EEA4CC 63.71%, #FFC1A9 99.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  max-width: 625px;
}

.revolution-img {
  width: 377px;
  height: 379px;
}

.get-started-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-top: 32px;
}

.get-started-section::before {
  background-image: linear-gradient(to right, #55299B, #FF84EB);
  opacity: 0.6;
}

.everything {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  color: #fff;
  max-width: 692px;
  margin-top: 12px;
}

.get-started-btn {
  margin-top: 24px;
  width: 222px;
  height: 48px;
  background: #F5D872;
  border-radius: 50px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 136.69%;
  text-align: center;
  color: #00008A;
}

.get-started-btn:hover {
  background: #fff;
  box-shadow: 0px 4px 35px rgba(104, 103, 240, 0.51);
}
.get-started-btn:active {
  background: #fff;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .blue-bar {
    width: calc(100vw - 84px);
    gap: 32px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
  .blue-bar {
    gap: 40px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .blue-bar {
    margin: 0 auto 32px auto;
    gap: 120px;
    max-width: 1440px;
  }
}

@media screen and (max-width: 520px) {
  .blue-bar {
    flex-direction: column;
    gap: 16px;
    max-height: 470px;
    margin: 18px;
    width: auto;
  }
  .blue-bar-content {
    flex-direction: column;
    gap: 16px;
  }

  .revolutionize {
    font-size: 16px;
    max-width: 288px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .revolutionize img {
    width: 288px;
    height: 50px;
  }
  .map-section {
    margin: 28px auto 20px auto;
  }
  .map-section img {
    width: 242.6px;
    height: 121px;
  }
  .everything {
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    max-width: 254px;
  }
  .get-started-section {
    margin-top: 0;
  }
  .get-started-btn {
    width: 140px;
    height: 30px;
    font-size: 14px;
    border-radius: 50px;
    margin: 2px auto 0 auto;
  }
  .switch-img {
    margin-left: 42px;
    width: 182px;
    margin-top: -12px;
  }
  .switch-btn-group {
    margin-top: -12px;
  }
}