.footer-background {
  background: #0B0C29;
}

.footer-container {
  padding: 56px 98px 16px 98px;
}

.top-side {
  display: flex;
  justify-content: space-between;
}

.middle-side {
  display: flex;
  gap: 428px;
}

.country {
  color: #fff;
}

.address-section {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.contact-us-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-us {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 136.69%;
  color: #FFF;
}

.mt-6 {
  margin-top: 80px;
}

.contact-sep {
  margin-left: -16px;
  color: #fff;
}

.country {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 136.69%;
  color: #FFFFFF;
}

.email {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 136.69%;
  color: #FFFFFF;
}

.phone {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136.69%;
  color: #FFFFFF;
}

.backed-section {
  display: flex;
  margin-left: 140px;
}

.backed-by {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 136.69%;
  text-align: center;
  color: #F5F5FA;
  margin: 10px 14px 0 0;
}

.allin-img {
  width: 96px;
  height: 46px;
}

.footer-line {
  margin-top: 56px;
  display: flex;
  justify-content: center;
}

.footer-bar {
  text-align: center;
  padding-bottom: 19px;
  padding-top: 16px;
  color: var(--lighter-gray, #B8B8D5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 136.687%; /* 19.136px */
  font-family: 'Open Sans';
}

.company-section {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.company-name {
  color: #FFF;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.687%; /* 32.805px */
}

.address {
  color: #FFF;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 167.023%; /* 26.724px */
  max-width: 376px;
}

.mobile-no {
  color: #FFF;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 167.023%; /* 26.724px */
}

.policy-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}

.divider {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.687%;
}

.link {
  cursor: pointer;
  color: #FFF;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 136.687%; /* 27.337px */
  text-decoration-line: underline;
}

@media screen and (max-width: 520px) {
  .footer-container {
    flex-direction: column;
    padding: 16px 32px;
  }
  .contact-us-section {
    flex-direction: column;
    margin-top: 12px;
  }
  .divider {
    width: 12px;
    height: 1px;
    background: #F5D872;
    margin-top: 24px;
  }
  .backed-section {
    flex-direction: column;
    margin: 14px 0 0 0;
  }
  .backed-section img {
    width: 78px;
    height: 36px;
  }
  .backed-by {
    margin-bottom: 12px;
    margin-right: 0;
    font-size: 14px;
    font-weight: 400;
  }
  .contact-us {
    font-size: 28px;
  }
  .email {
    font-size: 16px;
  }
  .phone {
    font-size: 14px;
  }
  .footer-line {
    margin-top: 32px;
  }
  .footer-bar {
    font-size: 12px;
    margin-top: 16px;
  }
  .top-side {
    align-items: center;
  }
  .middle-side {
    flex-direction: column;
    gap: 35px;
  }
  .policy-flex {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
  .mt-6 {
    margin-top: 24px;
  }
  .company-name {
    font-size: 18px;
  }
  .address-section {
    gap: 36px;
  }
  .address {
    font-size: 14px;
  }
  .link {
    font-size: 16px;
  }
}