.email-container {
  margin-top: 126px;
  margin-left: 100px;
  margin-right: 104px;
  margin-bottom: 50px;
}
.img-back {
  background-image: url('../../assets/icons/email_access.png');
  background-size: 100% 100%;
  width: 100%;
  height: 402px;
}
.bottom-text {
  font-family: 'Montserrat', sans-serif;
  margin-top: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 136.69%;
  text-align: center;
  color: rgba(201, 216, 255, 0.86);
}
.being-early {
  font-family: 'Open Sans';
  padding-top: 72px;
  font-style: normal;
  font-weight: 500;
  font-size: 53px;
  line-height: 126%;
  color: #fff;
}
.get-early-access {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 53px;
  line-height: 126%;
  color: #F5D872;
}
.email-input-group {
  margin-top: 32px;
}
.email-input {
  width: 501px;
  height: 56px;
  background: #FFFFFF;
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding-left: 32px;
}
.submit-btn {
  font-family: 'Montserrat', sans-serif;
  width: 221px;
  height: 55px;
  background: linear-gradient(90deg, #6867F0 16.22%, #7D59E9 84.01%);
  border: 1px solid #3825C6;
  box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 136.69%;
  color: #fff;
  margin: 32px 0 0 32px;
}
.submit-btn:hover {
  background: #222186;
  color: #fff;
  border: none;
}
.submit-btn:active {
  background-color: #222186;
  color: #fff;
  border: none;
}
.checkbox-img {
  margin-top: 51px;
  width: 112px;
  height: 112px;
}
.awesome {
  margin-top: 28px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 141.19%;
  text-align: center;
  color: #F5D872;
}
.check-email {
  margin-top: 16px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 141.19%;
  text-align: center;
  color: #fff;
}
.join-btn-bottom {
  width: 156px;
  height: 38px;
  margin-top: 24px;
  background: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(247, 169, 214, 1);
}

.join-btn-bottom:hover {
  background: #F5D872;
  color: #3825C6;
}
.join-btn-bottom:active {
  background: #F5D872;
}
@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .email-container {
    margin-top: 120px;
  }
  .img-back {
    max-width: 1440px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 520px) {
  .email-container {
    margin: 0;
    margin-top: 40px;
    border-radius: 0 !important;
  }
  .img-back {
    height: 288px;
    background-image: url('../../assets/icons/mob_email_access.png');
    background-size: 100% 100%;
    width: 100%;
  }
  .email-input {
    width: 303px;
    height: 48px;
  }
  .submit-btn {
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    width: 170px;
    height: 36px;
    background: linear-gradient(90deg, #6867F0 16.22%, #7D59E9 84.01%);
    border: 1px solid #3825C6;
    box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 136.69%;
    color: #fff;
    margin-left: 0;
  }
  .submit-btn:hover {
    background: #222186;
    color: #fff;
    border: none;
  }
  .submit-btn:active {
    background-color: #222186;
    color: #fff;
    border: none;
  }
  .bottom-text {
    margin: 30px auto 0 auto;
    max-width: 232px;
  }
  .being-early {
    font-size: 23px;
    padding-top: 56px;
  }
  .get-early-access {
    font-size: 23px;
  }
  .bottom-text {
    font-size: 10px;
    max-width: 250px;
  }
  .checkbox-img {
    width: 84px;
    height: 84px;
  }
  .awesome {
    font-size: 24px;
  }
  .check-email {
    font-size: 24px;
  }
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}