.how-works-container {
  background: #100E30;
  padding-bottom: 139px;
}

.how-title {
  padding-top: 104px;
  font-family: 'Gordita' Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 142.18%;
  text-align: center;
  background: linear-gradient(89.82deg, #98FCDA 1.17%, #C78FFF 32.34%, #EEA4CC 63.71%, #FFC1A9 99.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.globe-platform {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 142.18%;
  text-align: center;
  color: #C1C1D0;
  margin: 32px auto 0 auto;
  max-width: 1090px;
}

.gradient-yellow {
  width: 54px;
  height: 2px;
  background: #F5D872;
  margin: 53px auto 0 auto;
}

.how-desc {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #F5F5F5;
  margin-top: 20px;
}

.see-img {
  margin-left: 124px;
  width: 550px;
  height: 84px;
  margin-top: 92px;
  margin-bottom: -50px;
}


.tab-section {
  background: #181642;
  backdrop-filter: blur(87px);
  border-radius: 24px;
  padding: 45px 51px 84px 51px;
  margin: 60px auto 0 auto;
  max-width: 1092px;
  border: 1px solid #1D1A55;
}

.desktop-tabs {
  border-bottom: 1px solid #8E8A7B;
}
.tab-group {
  text-align: center;
}

.tab-item {
  padding-bottom: 27px;
  max-width: auto !important;
  flex: 1 !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 136.69%;
  text-align: center;
  color: #6C7891;
  cursor: pointer;
}

.tabActive {
  border-bottom: 3px solid #F5D872;
  color: #F5D872;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 136.69%;
}

.tab-container {
  display: flex;
  gap: 64px;
  margin-top: 80px;
}

.tab-header {
  font-family: 'Gordita Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.02em !important;
  color: #F7F9FF;
}
.tab-info {
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  max-width: 460px;
  color: #E4E4FF;
}
.tab-img {
  width: 487px;
  height: 302.88px;
}
.book-demo-btn {
  width: 222px;
  height: 48px;
  background: #F5D872;
  border: 1px solid #F5D872;
  border-radius: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #00008A;
}

.book-demo-btn:hover {
  background: #fff;
  color: #6867F0;
  box-shadow: 0px 4px 35px rgba(104, 103, 240, 0.51);
}

.bottom-button {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.mob-bottom-button {
  display: none;
}

@media screen and (max-width: 520px) {
  .how-works-container {
    padding-bottom: 48px;
  }
  .how-title {
    font-size: 16px;
    padding-top: 30px;
  }
  .how-title img {
    width: 154px;
    height: 23px;
  }
  .globe-platform {
    font-size: 14px;
    max-width: 270px;
    margin: 20px auto 0 auto;
  }
  .how-desc {
    font-size: 12px;
    margin: 12px auto 0 auto;
    max-width: 270px;
  }
  .gradient-yellow {
    width: 22px;
    margin-top: 30px;
  }
  .see-img {
    margin-left: 16px;
    width: 170px;
    height: 32px;
    margin-top: 24px;
    margin-bottom: -20px;
  }
  .accord-body {
    border: 1px solid rgba(247, 169, 214, 1);
    border-radius: 8px;
    margin: 18px;
    box-shadow: 0px 0px 12px rgba(104, 103, 240, 0.48);
  }
  .accord-header{
    padding: 18px 15px;
  }
  .accord-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 136.69%;
    text-align: center;
    color: #F5F5FA;
  }
  .accord-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .accord-msg {
    transform-origin: top center;
    animation: rotateX 300ms ease-in-out forwards;
  }
  .accord-animation-reverse {
    animation-direction: reverse;
  }
  @keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
  .accordion-container {
    border-radius: 8px;
  }
  .thumbnail-img {
    width: 239px;
    height: 149px;
    margin: 0 auto;
  }
  .create-img {
    background-image: url('../../assets/icons/Create.svg');
    background-size: 100% 100%;
  }
  .manage-img {
    background-image: url('../../assets/icons/Manage.svg');
    background-size: 100% 100%;
  }
  .collab-img {
    background-image: url('../../assets/icons/Collaborate.svg');
    background-size: 100% 100%;
  }
  .analyze-img {
    background-image: url('../../assets/icons/Analyze.svg');
    background-size: 100% 100%;
  }
  .tab-header {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }
  .tab-info {
    font-size: 14px;
    text-align: center;
  }
  .accord-content {
    padding: 1rem;
    margin: 0 18px 18px 18px;
    border: 1px solid rgba(247, 169, 214, 1);
    border-radius: 10px;
  }
  .bottom-button {
    display: none;
  }
  .mob-bottom-button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  .book-demo-btn {
    width: 160px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
  }
}